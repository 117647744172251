import { BooleanInputFieldType, GenderInputFieldType, RelationInputFieldType, StringInputFieldType } from "./InputFieldTypes";

export interface IFormData {
  isNewFamily?: boolean;
  familyId?: string;
  guardians: Guardian[];
  pupils: Pupil[];
  rulesAndPersonInfoAccepted: BooleanInputFieldType;
  recaptchaToken?: string;
};

export class FormData implements IFormData {
  isNewFamily?: boolean;
  familyId?: string;
  guardians: Guardian[] = [new Guardian(), new Guardian()];
  pupils: Pupil[] = [new Pupil()];
  rulesAndPersonInfoAccepted: BooleanInputFieldType = new BooleanInputFieldType(false);
  recaptchaToken?: string;
};

export class Guardian {
  id?: string | null;
  relation: RelationInputFieldType;
  firstName: StringInputFieldType;
  lastName: StringInputFieldType;
  mobileNumber: StringInputFieldType;
  email: StringInputFieldType;

  constructor(
    id: string | null = null,
    relation: RelationInputFieldType = new RelationInputFieldType(),
    firstName: StringInputFieldType = new StringInputFieldType(),
    lastName: StringInputFieldType = new StringInputFieldType(),
    mobileNumber: StringInputFieldType = new StringInputFieldType(),
    email: StringInputFieldType = new StringInputFieldType()
  ) {
    this.id = id;
    this.relation = relation;
    this.firstName = firstName;
    this.lastName = lastName;
    this.mobileNumber = mobileNumber;
    this.email = email;
  }
}

export class Pupil {
  firstName: StringInputFieldType;
  lastName: StringInputFieldType;
  gender: GenderInputFieldType;
  socialSecurityNumber: StringInputFieldType;
  address: StringInputFieldType;
  zipCode: StringInputFieldType;
  postAddress: StringInputFieldType;

  constructor(
    firstName: StringInputFieldType = new StringInputFieldType(),
    lastName: StringInputFieldType = new StringInputFieldType(),
    gender: GenderInputFieldType = new GenderInputFieldType(),
    socialSecurityNumber: StringInputFieldType = new StringInputFieldType(),
    address: StringInputFieldType = new StringInputFieldType(),
    zipCode: StringInputFieldType = new StringInputFieldType(),
    postAddress: StringInputFieldType = new StringInputFieldType()
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;
    this.socialSecurityNumber = socialSecurityNumber;
    this.address = address;
    this.zipCode = zipCode;
    this.postAddress = postAddress;
  }
}