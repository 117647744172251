export function Footer() {
  return (
      <footer className="footer">
        <hr />
        <address className="content has-text-center is-size-7" dir='ltr' style={{fontFamily: "sans-serif"}}>
          <b>Althaqalayn studiecenter</b> by kulturföreningen Al-Wahda<br />
          Datavägen 2B, 175 43 Järfälla, <a href="mailto:info@althaqalayn.se">info@althaqalayn.se</a><br />
          Orgnr: 802407-1519
        </address>
      </footer>
  )
}