import { useState } from "react";
import { FormData, Pupil } from "./domain/FormData";
import { FormState } from "./domain/FormState";
import { GenderType } from "./domain/GenderType";
import { GenderInputFieldType } from "./domain/InputFieldTypes";
import {
  ProcessSocialSecurityNumberInput,
  ProcessStringInput,
  ProcessZipCodeInput,
} from "./processInput";

export function SetPupilInfo({
  formData,
  setFormData,
  formState,
  setFormState,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}) {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>(undefined);

  function updatePupilData(
    idx: number,
    valueType: string,
    value: string,
    isRequired: boolean,
    doErrorValidation: boolean
  ) {
    const pupils = formData.pupils.map((p, _i) => {
      if (_i === idx) {
        switch (valueType) {
          case "firstName":
            return {
              ...p,
              firstName: ProcessStringInput(
                p.firstName,
                value,
                isRequired,
                true,
                true,
                doErrorValidation
              ),
            };
          case "lastName":
            return {
              ...p,
              lastName: ProcessStringInput(
                p.lastName,
                value,
                isRequired,
                true,
                true,
                doErrorValidation
              ),
            };
          case "gender":
            const genderInput = new GenderInputFieldType(value as GenderType, undefined);
            if (doErrorValidation && isRequired && genderInput.value === GenderType.UNDEFINED) {
              genderInput.errorMessage = "هذا الحقل إلزامي";
            }
            let socialSecurityNumber = p.socialSecurityNumber;
            if (p.socialSecurityNumber.value) {
              socialSecurityNumber = ProcessSocialSecurityNumberInput(
                p.socialSecurityNumber,
                p.socialSecurityNumber.value,
                isRequired,
                doErrorValidation,
                genderInput.value
              )
            }
            return {
              ...p,
              gender: genderInput,
              socialSecurityNumber: socialSecurityNumber
            };
          case "socialSecurityNumber":
            return {
              ...p,
              socialSecurityNumber: ProcessSocialSecurityNumberInput(
                p.socialSecurityNumber,
                value,
                isRequired,
                doErrorValidation,
                p.gender.value
              ),
            };
          case "address":
            return {
              ...p,
              address: ProcessStringInput(
                p.address,
                value,
                isRequired,
                true,
                false,
                doErrorValidation
              ),
            };
          case "zipCode":
            return {
              ...p,
              zipCode: ProcessZipCodeInput(
                p.zipCode,
                value,
                isRequired,
                doErrorValidation
              ),
            };
          case "postAddress":
            return {
              ...p,
              postAddress: ProcessStringInput(
                p.postAddress,
                value,
                isRequired,
                true,
                true,
                doErrorValidation
              ),
            };
          default:
            return p;
        }
      } else {
        return p;
      }
    })

    let inputsAreOk = true;
    pupils.forEach((p, _idx) =>
      inputsAreOk =
        p.firstName.errorMessage ||
        p.lastName.errorMessage ||
        p.gender.errorMessage ||
        p.socialSecurityNumber.errorMessage ||
        (_idx === 0 &&
          (p.address.errorMessage ||
            p.zipCode.errorMessage ||
            p.postAddress.errorMessage))
          ? false
          : inputsAreOk
    )

    if (inputsAreOk) {
      setSubmitErrorMessage(undefined);
    }

    setFormData({
      ...formData,
      pupils: pupils,
    });
  }

  function handleNextButtonPressed() {
    let inputsAreOk = true;
    setFormData({
      ...formData,
      pupils: formData.pupils.map((p, _idx) => {
        let validatedPupil = new Pupil(
          ProcessStringInput(p.firstName, p.firstName.value, true, true, true, true),
          ProcessStringInput(p.lastName, p.lastName.value, true, true, true, true),
          {
            ...p.gender,
            errorMessage:
              p.gender.value === GenderType.UNDEFINED
                ? "هذا الحقل إلزامي"
                : undefined,
          },
          ProcessSocialSecurityNumberInput(
            p.socialSecurityNumber,
            p.socialSecurityNumber.value,
            true,
            true,
            p.gender.value
          ),
          ProcessStringInput(p.address, p.address.value, true, true, false, true),
          ProcessZipCodeInput(p.zipCode, p.zipCode.value, true, true),
          ProcessStringInput(p.postAddress, p.postAddress.value, true, true, true, true)
        );

        inputsAreOk =
          validatedPupil.firstName.errorMessage ||
          validatedPupil.lastName.errorMessage ||
          validatedPupil.gender.errorMessage ||
          validatedPupil.socialSecurityNumber.errorMessage ||
          (_idx === 0 &&
            (validatedPupil.address.errorMessage ||
              validatedPupil.zipCode.errorMessage ||
              validatedPupil.postAddress.errorMessage))
            ? false
            : inputsAreOk;
        return validatedPupil;
      }),
    });
    if (inputsAreOk) {
      setSubmitErrorMessage(undefined);
      setFormState({ ...formState, goNext: true });
    } else {
      setSubmitErrorMessage("هنالك خطأ في حقل من الحقول أعلاه يُرجى مراجعتها.");
    }
  }

  return (
    <>
      <section className="main-content pt-5 px-5">
        <>
          {formData.pupils.map((pupil, _idx) => (
            <div key={_idx}>
              <p className="has-text-right has-text-danger block">
                طالب جديد {_idx + 1}
                {_idx > 0 && (
                  <a
                    href="#"
                    className="is-pulled-left"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        pupils: formData.pupils.filter((p, _i) => _i !== _idx),
                      })
                    }
                  >
                    حذف
                  </a>
                )}
              </p>

              <div className="mb-3">
                <input
                  className={`input ${
                    pupil.firstName.errorMessage ? "is-danger" : ""
                  }`}
                  type="text"
                  placeholder="Förnamn"
                  value={pupil.firstName.value}
                  onChange={(e) =>
                    updatePupilData(
                      _idx,
                      "firstName",
                      e.target.value,
                      true,
                      false
                    )
                  }
                  onBlur={(e) =>
                    updatePupilData(
                      _idx,
                      "firstName",
                      e.target.value,
                      true,
                      true
                    )
                  }
                />
                {pupil.firstName.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {pupil.firstName.errorMessage}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <input
                  className={`input ${
                    pupil.lastName.errorMessage ? "is-danger" : ""
                  }`}
                  type="text"
                  placeholder="Efternamn"
                  value={pupil.lastName.value}
                  onChange={(e) =>
                    updatePupilData(
                      _idx,
                      "lastName",
                      e.target.value,
                      true,
                      false
                    )
                  }
                  onBlur={(e) =>
                    updatePupilData(
                      _idx,
                      "lastName",
                      e.target.value,
                      true,
                      true
                    )
                  }
                />
                {pupil.lastName.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {pupil.lastName.errorMessage}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <div
                  className={`select is-normal is-fullwidth ${
                    pupil.gender.errorMessage ? "is-danger" : ""
                  }`}
                >
                  <select
                    value={pupil.gender.value}
                    onChange={(e) => updatePupilData(_idx, "gender", e.target.value, true, true)}
                  >
                    {Object.values(GenderType).map((value, index) => (
                      <option
                        key={index}
                        value={value}
                        hidden={value === GenderType.UNDEFINED ? true : false}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                {pupil.gender.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {pupil.gender.errorMessage}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <input
                  className={`input ${
                    pupil.socialSecurityNumber.errorMessage ? "is-danger" : ""
                  }`}
                  type="text"
                  placeholder="Personnummer (yymmdd-xxxx)"
                  pattern="[0-9]{6}-[0-9]{4}"
                  minLength={11}
                  maxLength={11}
                  value={pupil.socialSecurityNumber.value}
                  onChange={(e) =>
                    updatePupilData(
                      _idx,
                      "socialSecurityNumber",
                      e.target.value,
                      true,
                      false
                    )
                  }
                  onBlur={(e) =>
                    updatePupilData(
                      _idx,
                      "socialSecurityNumber",
                      e.target.value,
                      true,
                      true
                    )
                  }
                />
                {pupil.socialSecurityNumber.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {pupil.socialSecurityNumber.errorMessage}
                  </p>
                )}
              </div>

              <div hidden={_idx > 0}>
                <div className="mb-3">
                  <input
                    className={`input ${
                      pupil.address.errorMessage ? "is-danger" : ""
                    }`}
                    type="text"
                    placeholder="Adress"
                    value={pupil.address.value}
                    onChange={(e) =>
                      updatePupilData(
                        _idx,
                        "address",
                        e.target.value,
                        true,
                        false
                      )
                    }
                    onBlur={(e) =>
                      updatePupilData(
                        _idx,
                        "address",
                        e.target.value,
                        true,
                        true
                      )
                    }
                  />
                  {pupil.address.errorMessage && (
                    <p
                      className="help is-danger block"
                      style={{ direction: "rtl" }}
                    >
                      {pupil.address.errorMessage}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <input
                    className={`input ${
                      pupil.zipCode.errorMessage ? "is-danger" : ""
                    }`}
                    type="text"
                    placeholder="Postnummer"
                    pattern="[0-9]{3} [0-9]{2}"
                    minLength={6}
                    maxLength={6}
                    value={pupil.zipCode.value}
                    onChange={(e) =>
                      updatePupilData(
                        _idx,
                        "zipCode",
                        e.target.value,
                        true,
                        false
                      )
                    }
                    onBlur={(e) =>
                      updatePupilData(
                        _idx,
                        "zipCode",
                        e.target.value,
                        true,
                        true
                      )
                    }
                  />
                  {pupil.zipCode.errorMessage && (
                    <p
                      className="help is-danger block"
                      style={{ direction: "rtl" }}
                    >
                      {pupil.zipCode.errorMessage}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <input
                    className={`input ${
                      pupil.postAddress.errorMessage ? "is-danger" : ""
                    }`}
                    type="text"
                    placeholder="Postort"
                    value={pupil.postAddress.value}
                    onChange={(e) =>
                      updatePupilData(
                        _idx,
                        "postAddress",
                        e.target.value,
                        true,
                        false
                      )
                    }
                    onBlur={(e) =>
                      updatePupilData(
                        _idx,
                        "postAddress",
                        e.target.value,
                        true,
                        true
                      )
                    }
                  />
                  {pupil.postAddress.errorMessage && (
                    <p
                      className="help is-danger block"
                      style={{ direction: "rtl" }}
                    >
                      {pupil.postAddress.errorMessage}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
          {formData.pupils.length < 6 && (
            <button
              className="button is-fullwidth is-lighter is-outlined"
              style={{ justifyContent: "center" }}
              onClick={() =>
                setFormData({
                  ...formData,
                  pupils: [...formData.pupils, new Pupil()],
                })
              }
            >
              <strong>أضف طالب آخر</strong>
            </button>
          )}
        </>
        <div className=" main-center-content">
          <div className="buttons are-medium pt-4">
            <button
              onClick={() => setFormState({ ...formState, goBack: true })}
              className="button is-rounded"
              style={{ minWidth: "125px" }}
            >
              السابق
            </button>
            <button
              onClick={() => handleNextButtonPressed()}
              className="button is-rounded is-danger"
              style={{ minWidth: "125px" }}
            >
              التالي
            </button>
          </div>
        </div>
        {submitErrorMessage && (
          <p className="help is-danger block" style={{ direction: "rtl", whiteSpace: 'pre-wrap' }}>
            {submitErrorMessage}
          </p>
        )}
      </section>
    </>
  );
}
