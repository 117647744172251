import { useState } from "react";
import { FormData, Guardian, Pupil } from "./domain/FormData";
import { FormState } from "./domain/FormState";
import { useLazyQuery } from "@apollo/client";
import { gql } from "../../__generated__/gql";
import { RelationInputFieldType, StringInputFieldType } from "./domain/InputFieldTypes";
import { formatTenDigitSocialSecurityNumber, ProcessSocialSecurityNumberInput } from "./processInput";
import { convertGqlToRelationType, RelationType } from "./domain/RelationType";
import { GenderType } from "./domain/GenderType";

export function GetExistingFamily({
  formData,
  setFormData,
  formState,
  setFormState,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}) {
  const [socialSecurityNumberInput, setSocialSecurityNumberInput] =
    useState<StringInputFieldType>(new StringInputFieldType());

  const GET_FAMILY_BY_PUPIL_SSN = gql(/* GraphQL */ `
    query getFamilyByPupilSocialSecurityNumber($socialSecurityNumber: String!) {
      getFamilyByPupilSocialSecurityNumber(
        socialSecurityNumber: $socialSecurityNumber
      ) {
        id
        pupils {
          firstName
          lastName
          address
          zipCode
          postAddress
        }
        guardians {
          id
          relationToPupil
          firstName
          lastName
          mobileNumber
          email
        }
      }
    }
  `);

  const [getFamily, { loading, error, data }] = useLazyQuery(
    GET_FAMILY_BY_PUPIL_SSN
  );

  function updateSocialSecurityNumberInput(
    value: string,
    isRequired: boolean,
    doErrorValidation: boolean
  ) {
    const formatedValue = formatTenDigitSocialSecurityNumber(value.trim());
    const validate = doErrorValidation || !!formatedValue.match(/[0-9]{6}[+-][0-9]{4}/g)
    const socialSecurityNumberResult = ProcessSocialSecurityNumberInput(
      socialSecurityNumberInput,
      formatedValue,
      isRequired,
      validate,
      GenderType.UNDEFINED
    );
    setSocialSecurityNumberInput(socialSecurityNumberResult);
    if (
      validate &&
      !socialSecurityNumberResult.errorMessage
    ) {
      getFamily({
        variables: { socialSecurityNumber: socialSecurityNumberResult.value! },
      });
    }
  }

  function handleNextButtonPressed() {
    if (data?.getFamilyByPupilSocialSecurityNumber) {
      const firstPupil = data.getFamilyByPupilSocialSecurityNumber.pupils[0];
      setFormData({
        ...formData, 
        familyId: data.getFamilyByPupilSocialSecurityNumber.id, 
        guardians: data.getFamilyByPupilSocialSecurityNumber.guardians.map((g) => (
          new Guardian(
            g.id,
            new RelationInputFieldType(convertGqlToRelationType(g.relationToPupil) as RelationType), 
            new StringInputFieldType(g.firstName), 
            new StringInputFieldType(g.lastName), 
            new StringInputFieldType(g.mobileNumber), 
            new StringInputFieldType(g.email)
          )
        )),
        pupils: [new Pupil(
          undefined,
          undefined,
          undefined,
          undefined,
          new StringInputFieldType(firstPupil.address),
          new StringInputFieldType(firstPupil.zipCode),
          new StringInputFieldType(firstPupil.postAddress)
        )]
      })
      setFormState({ ...formState, goNext: true })
    }
  }

  return (
    <>
      <section className="main-content main-center-content px-5">
        <div className="title pt-6">يرجى إدخال الرقم<br /> الشخصي لأحد الإخوة</div>
        <input
          className="input block"
          type="text"
          placeholder="Personnummer (yymmdd-xxxx)"
          pattern="[0-9]{6}-[0-9]{4}"
          minLength={11}
          maxLength={11}
          value={socialSecurityNumberInput.value}
          onChange={(e) =>
            updateSocialSecurityNumberInput(e.target.value, true, false)
          }
          onBlur={(e) =>
            updateSocialSecurityNumberInput(e.target.value, true, true)
          }
        />

        {((!loading && !error && data) || socialSecurityNumberInput.errorMessage) && (
            <>
              <div
                className="block is-size-5"
                style={{
                  width: "100%",
                  borderStyle: "solid",
                  borderColor: "lightgrey",
                  borderWidth: "1px",
                  borderRadius: "5px",
                }}
              >
                <div className="p-4">
                  { socialSecurityNumberInput.errorMessage &&
                  <p>{socialSecurityNumberInput.errorMessage}</p>
                  }
                  { !socialSecurityNumberInput.errorMessage && !data?.getFamilyByPupilSocialSecurityNumber &&
                  <p>هذا الرقم الشخصي لا يعود الى طالب في المدرسة. الرجاء مراجعة الرقم الشخصي أو الرجوع واختيار عدم وجود أخ في المدرسة.</p>
                  }
                  { !socialSecurityNumberInput.errorMessage && data?.getFamilyByPupilSocialSecurityNumber &&
                  <>
                  <p className="has-text-danger block">
                    وجدنا الأخوة وأولياء أمورهم
                  </p>
                  <p>
                      {
                        data?.getFamilyByPupilSocialSecurityNumber?.pupils.map(
                          (p, i) => (
                            <strong key={i}>
                              {p.firstName} {p.lastName}
                              <br />
                            </strong>
                          )
                        )
                      }
                      </p>
                      <p>
                      {
                        data?.getFamilyByPupilSocialSecurityNumber?.guardians.map(
                          (g, i) => (<span key={i}>{g.firstName} {g.lastName}<br /></span>)
                        )
                      }
                  </p>
                  </>
                  }
                </div>
              </div>
            </>
          )}

        <div className="buttons are-medium pt-4">
          <button
            onClick={() => setFormState({ ...formState, goBack: true })}
            className="button is-rounded"
            style={{ minWidth: "125px" }}
          >
            السابق
          </button>
          <button
            onClick={() => handleNextButtonPressed()}
            className={`button is-rounded is-danger ` + (loading ? "is-loading" : "")}
            style={{ minWidth: "125px" }}
            disabled={
              !(
                !loading &&
                !error &&
                data?.getFamilyByPupilSocialSecurityNumber &&
                !socialSecurityNumberInput.errorMessage
              )
            }
          >
            التالي
          </button>
        </div>
      </section>
    </>
  );
}
