import { GenderType } from "./GenderType";
import { RelationType } from "./RelationType";

export class BooleanInputFieldType {
  value: boolean;
  errorMessage?: string;

  constructor(value: boolean, errorMessage?: string) {
    this.value = value;
    this.errorMessage = errorMessage;
  }
}

export class StringInputFieldType {
  value: string;
  errorMessage?: string;

  constructor(value: string = '', errorMessage?: string) {
    this.value = value;
    this.errorMessage = errorMessage;
  }
}

export class RelationInputFieldType {
  value: RelationType;
  errorMessage?: string;

  constructor(value?: RelationType, errorMessage?: string) {
    this.value = value ? value : RelationType.UNDEFINED;
    this.errorMessage = errorMessage;
  }
}

export class GenderInputFieldType {
  value: GenderType;
  errorMessage?: string;

  constructor(value?: GenderType, errorMessage?: string) {
    this.value = value ? value : GenderType.UNDEFINED;
    this.errorMessage = errorMessage;
  }
}
