export interface IFormState {
  currentPage: number;
  goNext: boolean;
  goBack: boolean;
}

export class FormState implements IFormState {
  currentPage: number;
  goNext: boolean = false;
  goBack: boolean = false;

  
  constructor (currentPage: number) {
    this.currentPage = currentPage;
  }
}
