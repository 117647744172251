import { Banner } from "../banner";
import { Footer } from "../footer";
import { FormData } from "./domain/FormData";
import { FormState } from "./domain/FormState";
import { GenderType } from "./domain/GenderType";

export function Confirmation({
  formData,
  setFormData,
  formState,
  setFormState,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}) {

  const pupilsNames = formData.pupils.map((p) => {
    const title = p.gender.value.valueOf() === Object.keys(GenderType)[Object.values(GenderType).indexOf(GenderType.FEMALE as unknown as GenderType)] ? "الطالبة" : "الطالب";
    return title + " " + p.firstName.value + " " + p.lastName.value;
  });
  const concatinatedPupilsNames = pupilsNames.join(" و");

  return (
    <>
      <div className="main-side">
      <Banner />
        <section className="main-content main-center-content pt-6 px-5">
          <figure className="image is-128x128">
          <img src={require('../../assets/heavy_check.png')} />

            </figure>
          <div className="is-size-4 has-text-danger pt-6 px-6">
            شكرا لكم<br />
            تم استلام التسجيل
          </div>
          <div className="block is-size-6 px-5 pt-3">
            نأكد لكم استلام تسجيل {concatinatedPupilsNames} وقد أُرسلت لكم رسالة تأكيد استلام طلبكم إلى بريدكم الالكتروني مع معلومات حول إجراءات ومراحل القبول.
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}