import React, { useRef, useState } from "react";
import { FormData, Guardian, Pupil } from "./domain/FormData";
import { FormState } from "./domain/FormState";
import ReCAPTCHA from "react-google-recaptcha";
import { convertRelationTypeToGql, RelationType } from "./domain/RelationType";
import {
  ProcessEmailInput,
  ProcessMobileNumberInput,
  ProcessSocialSecurityNumberInput,
  ProcessStringInput,
  ProcessZipCodeInput,
} from "./processInput";
import { BooleanInputFieldType } from "./domain/InputFieldTypes";
import { gql } from "../../__generated__/gql";
import {
  GenderType as GenderTypeGQL,
} from "../../__generated__/graphql";
import { useMutation } from "@apollo/client";
import { GenderType } from "./domain/GenderType";

export function InputConfirmation({
  formData,
  setFormData,
  formState,
  setFormState,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}) {
  const CREATE_PUPILS = gql(/* GraphQL */ `
    mutation createPupila($createPupilsInput: CreatePupilsInput!) {
      createPupils(input: $createPupilsInput) {
        results {
          __typename
          ... on CreatePupilSuccessful {
            pupil {
              familyId
              socialSecurityNumber
              firstName
              lastName
            }
          }
          ... on CreatePupilFailed {
            socialSecurityNumber
            reason
          }
        }
      }
    }
  `);

  const [createPupils, { loading }] = useMutation(CREATE_PUPILS);

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>(undefined);

  function addToSubmitErrorMessage(newErrorMessage: string, currentErrorMessage: string): string {
    let errorMessage = currentErrorMessage.length !== 0 ? currentErrorMessage + "\n" : "";
    let concatinatedMessage = errorMessage + newErrorMessage
    setSubmitErrorMessage(concatinatedMessage);
    return concatinatedMessage;
  }

  function handleRulesAcceptionChange(checked: boolean) {
    const errorMessage = checked ? undefined : "هذا الحقل إلزامي";
    setFormData({
      ...formData,
      rulesAndPersonInfoAccepted: new BooleanInputFieldType(
        checked,
        errorMessage
      ),
    });
  }

  function handleRecaptchaChange(value: string) {
    //console.log("Captcha value:", value);
  }

  const handleSubmit = async () => {
    if (formData.guardians.length === 0) {
      setSubmitErrorMessage(
        "نفتقد معلومات لتسجيل أي ولي أمر. الرجاء التواصل مع إدراة المدرسة إن تكرر هذا الأمر عبر البريد الالكتروني التالي مع رفق صورة من هذه الصفحة: info@althaqalaynse."
      );
      recaptchaRef.current.reset();
      return;
    }
    if (formData.pupils.length === 0) {
      setSubmitErrorMessage(
        "نفتقد معلومات لتسجيل أي طالب. الرجاء التواصل مع إدراة المدرسة إن تكرر هذا الأمر عبر البريد الالكتروني التالي مع رفق صورة من هذه الصفحة: info@althaqalaynse."
      );
      recaptchaRef.current.reset();
      return;
    }
    let guardianInputsAreOk = true;
    setFormData({
      ...formData,
      guardians: formData.guardians.map((g) => {
        let validatedGuardian = new Guardian(
          g.id,
          {
            ...g.relation,
            errorMessage:
              g.relation.value === RelationType.UNDEFINED
                ? "هذا الحقل إلزامي"
                : undefined,
          },
          ProcessStringInput(g.firstName, g.firstName.value, true, true, true, true),
          ProcessStringInput(g.lastName, g.lastName.value, true, true, true, true),
          ProcessMobileNumberInput(
            g.mobileNumber,
            g.mobileNumber.value,
            true,
            true
          ),
          ProcessEmailInput(g.email, g.email.value, true, true)
        );
        guardianInputsAreOk =
          validatedGuardian.firstName.errorMessage ||
          validatedGuardian.lastName.errorMessage ||
          validatedGuardian.mobileNumber.errorMessage ||
          validatedGuardian.email.errorMessage ||
          validatedGuardian.relation.errorMessage
            ? false
            : guardianInputsAreOk;
        return validatedGuardian;
      }),
    });
    if (!guardianInputsAreOk) {
      setSubmitErrorMessage(
        "هنالك خطأ في أحد الحقول في تسجيل أحد أولياء الأمور. الرجاء الضغط على تصحيح لأولياء الأمور ومراجعة الحقول"
      );
      recaptchaRef.current.reset();
      return;
    }
    let pupilInputsAreOk = true;
    setFormData({
      ...formData,
      pupils: formData.pupils.map((p, _idx) => {
        let validatedPupil = new Pupil(
          ProcessStringInput(p.firstName, p.firstName.value, true, true, true, true),
          ProcessStringInput(p.lastName, p.lastName.value, true, true, true, true),
          {
            ...p.gender,
            errorMessage:
              p.gender.value === GenderType.UNDEFINED
                ? "هذا الحقل إلزامي"
                : undefined,
          },
          ProcessSocialSecurityNumberInput(
            p.socialSecurityNumber,
            p.socialSecurityNumber.value,
            true,
            true,
            p.gender.value
          ),
          ProcessStringInput(p.address, p.address.value, true, true, false, true),
          ProcessZipCodeInput(p.zipCode, p.zipCode.value, true, true),
          ProcessStringInput(p.postAddress, p.postAddress.value, true, true, true, true)
        );
        pupilInputsAreOk =
        validatedPupil.firstName.errorMessage ||
          validatedPupil.lastName.errorMessage ||
          validatedPupil.gender.errorMessage ||
          validatedPupil.socialSecurityNumber.errorMessage ||
          (_idx === 0 &&
            (validatedPupil.address.errorMessage ||
              validatedPupil.zipCode.errorMessage ||
              validatedPupil.postAddress.errorMessage))
            ? false
            : pupilInputsAreOk;
        return validatedPupil;
      }),
    });
    if (!pupilInputsAreOk) {
      setSubmitErrorMessage(
        "هنالك خطأ في أحد الحقول في تسجيل أحد الطلاب. الرجاء الضغط على تصحيح لطلاب ومراجعة الحقول"
      );
      recaptchaRef.current.reset();
      return;
    }
    if (formData.isNewFamily !== true && !formData.familyId) {
      setSubmitErrorMessage(
        "حصل خطأ فني حيث نفتقد إلى الرمز الخاص بالعائلة. الرجاء التواصل مع إدراة المدرسة إن تكرر هذا الأمر عبر البريد الالكتروني التالي مع رفق صورة من هذه الصفحة: info@althaqalaynse."
      );
      recaptchaRef.current.reset();
      return;
    }
    if (formData.rulesAndPersonInfoAccepted.value === false) {
      setFormData({
        ...formData,
        rulesAndPersonInfoAccepted: {
          ...formData.rulesAndPersonInfoAccepted,
          errorMessage: "هذا الحقل إلزامي",
        },
      });
      recaptchaRef.current.reset();
      return;
    }

    const token = await recaptchaRef.current.executeAsync();
    setFormData({
      ...formData,
      recaptchaToken: token,
    });
    
    createPupils({
      variables: {
        createPupilsInput: {
          familyId: formData.familyId,
          guardians: formData.guardians.map((g) => ({
            relationToPupil: convertRelationTypeToGql(g.relation.value),
            firstName: g.firstName!.value!,
            lastName: g.lastName!.value!,
            email: g.email!.value!,
            mobileNumber: g.mobileNumber!.value!,
            address: formData.pupils[0].address!.value!,
            zipCode: formData.pupils[0].zipCode!.value!,
            postAddress: formData.pupils[0].postAddress!.value!,
          })),
          pupils: formData.pupils.map((p) => ({
            firstName: p.firstName!.value!,
            lastName: p.lastName!.value!,
            gender:
              p.gender.value === GenderType.FEMALE 
                ? GenderTypeGQL.Female
                : GenderTypeGQL.Male,
            socialSecurityNumber: p.socialSecurityNumber!.value!,
            address: formData.pupils[0].address!.value!,
            zipCode: formData.pupils[0].zipCode!.value!,
            postAddress: formData.pupils[0].postAddress!.value!,
          })),
          recaptchaToken: token,
        },
      },
      onCompleted(data, clientOptions) {
        let currentError = '';
        if (
          !data.createPupils.results.some(
            (v) => v.__typename !== "CreatePupilSuccessful"
          )
        ) {
          setFormState({ ...formState, goNext: true });
        } else {
          data?.createPupils.results.forEach((r) => {
            switch (r.__typename) {
              case "CreatePupilSuccessful":
                currentError = addToSubmitErrorMessage(
                  "• قد تم تسجيل الطالب بالرقم الشخصي" + r.pupil.socialSecurityNumber + " بنجاح.",
                    currentError
                );
                break;
              case "CreatePupilFailed":
                switch (r.reason) {
                  case "RECAPTCHA_TOKEN_VALIDATION_FAILED":
                    currentError = addToSubmitErrorMessage("• هنالك خطأ ما يتعلق برمز reCaptcha", currentError);
                    break;
                  case "PUPIL_ALREADY_EXISTS":
                    currentError = addToSubmitErrorMessage(
                      "• لم يتم تسجيل الطالب بالرقم الشخصي" + r.socialSecurityNumber +" هنالك طالب مُسَجّل في المدرسة بهذا الرقم.",
                      currentError
                    );
                    break;
                  case "ABORTED":
                  default:
                    currentError = addToSubmitErrorMessage(
                      "• حصل خطأ ما، لم يتم تسجيل الطالب بالرقم الشخصي" + r.socialSecurityNumber,
                      currentError
                    );
                    break;
                }
                break;
            }
          });
        }
      },
      onError(error, clientOptions) {
        recaptchaRef.current.reset();
        setSubmitErrorMessage("• جصل خطأ أثناء إرسال التسجيل. الرجاء المحاولة مرة أخرى وإن تكرر الأمر الرجاء التواصل مع إدراة المدرسة عبر البريد الالكتروني التالي مع رفق صورة من هذه الصفحة: info@althaqalaynse.\n"
          + "- " + error.name + "\n"
          + "- " + error.message + "\n"
          + "- " + error.extraInfo
        );
      },
    });
  };

  return (
    <>
      <section className="main-content pt-5 px-5">
        <p className="has-text-danger block has-text-right">تحقق من التفاصيل</p>
        <div
          className="has-text-left is-size-6 block"
          dir="ltr"
          style={{ fontFamily: "sans-serif" }}
        >
          {formData.guardians.map((g, _idx) => (
            <div key={_idx}>
              <hr />
              <p className="block">
              <a
                  href="#"
                  hidden={formData.isNewFamily === false && g.id !== null}
                  onClick={() => {
                    setFormState({ ...formState, currentPage: 3 });
                    setSubmitErrorMessage(undefined);
                  }}
                  className="is-pulled-right"
                >
                  تعديل
                </a>
                <strong>{g.relation.value}:</strong>
                <br />
                {g.firstName.value}
                <br />
                {g.lastName.value}
                <br />
                {g.mobileNumber.value}
                <br />
                {g.email.value}
                <br />
              </p>
            </div>
          ))}
          {formData.pupils.map((p, _idx) => (
            <div key={_idx}>
              <hr />
              <p className="block">
                <a
                  href="#"
                  onClick={() => {
                    setFormState({ ...formState, currentPage: 4 });
                    setSubmitErrorMessage(undefined);
                  }}
                  className="is-pulled-right"
                >
                  تعديل
                </a>
                <strong>Ny elev {_idx + 1}:</strong>
                <br />
                {p.firstName.value}
                <br />
                {p.lastName.value}
                <br />
                {p.gender.value}
                <br />
                {p.socialSecurityNumber.value}
                <br />
                {p.address.value}
                <br />
                {p.zipCode.value} {p.postAddress.value}
                <br />
              </p>
            </div>
          ))}
          <hr />
          <label className="checkbox has-text-right" dir="rtl" style={{ fontFamily: "GE Dinar One" }}>
            <input
              type="checkbox"
              checked={formData.rulesAndPersonInfoAccepted.value}
              onChange={(e) => handleRulesAcceptionChange(e.target.checked)}
            />
            <span className="px-2">
              <strong>
                أتعهد الإلتزام بشروط ومقررات المدرسة المذكورة{" "}
                <a href="/terms-and-conditions" target={"blank"}>
                  هنا
                </a>{" "}
                وأقبل التعامل مع معلوماتي الشخصية كما هو مذكور{" "}
                <a
                  href="https://althaqalayn.se/personuppgifter/"
                  target={"blank"}
                >
                  هنا
                </a>
                .
              </strong>
            </span>
          </label>
          {formData.rulesAndPersonInfoAccepted.errorMessage && (
            <p
              className="help is-danger block has-text-right"
              style={{ direction: "rtl" }}
            >
              {formData.rulesAndPersonInfoAccepted.errorMessage}
            </p>
          )}
        </div>

        <div className=" main-center-content">
          <div className="buttons are-medium pt-4">
            <button
              onClick={() => setFormState({ ...formState, goBack: true })}
              className="button is-rounded"
              style={{ minWidth: "125px" }}
            >
              السابق
            </button>
            <button
              onClick={() => handleSubmit()}
              className={`button is-rounded is-success ` + (loading ? "is-loading" : "")}
              style={{ minWidth: "125px" }}
            >
              إرسال
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                ref={recaptchaRef}
                onChange={handleRecaptchaChange}
                size="invisible"
              />
            </button>
          </div>
        </div>
        {submitErrorMessage && (
          <p className="help is-danger block" style={{ direction: "rtl", whiteSpace: 'pre-wrap' }}>
            {submitErrorMessage}
          </p>
        )}
      </section>
    </>
  );
}
