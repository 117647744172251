/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Timestamp encoded with ISO-8601 instant format */
  Instant: any;
  /**
   * Common scalars
   *
   * UUID encoded as a "canonical textual representation" https://en.wikipedia.org/wiki/Universally_unique_identifier#Format
   */
  UUID: any;
};

export type CreatePupilFailed = {
  __typename?: 'CreatePupilFailed';
  reason: CreatePupilFailedReason;
  socialSecurityNumber: Scalars['String'];
};

export enum CreatePupilFailedReason {
  Aborted = 'ABORTED',
  PupilAlreadyExists = 'PUPIL_ALREADY_EXISTS',
  RecaptchaTokenValidationFailed = 'RECAPTCHA_TOKEN_VALIDATION_FAILED'
}

export type CreatePupilResult = CreatePupilFailed | CreatePupilSuccessful;

export type CreatePupilSuccessful = {
  __typename?: 'CreatePupilSuccessful';
  pupil: Pupil;
};

export type CreatePupilsExistingFamilyInput = {
  familyId: Scalars['UUID'];
  pupils: Array<PupilInput>;
  recaptchaToken: Scalars['String'];
};

export type CreatePupilsInput = {
  familyId?: InputMaybe<Scalars['UUID']>;
  guardians: Array<GuardianInput>;
  pupils: Array<PupilInput>;
  recaptchaToken: Scalars['String'];
};

export type CreatePupilsNewFamilyInput = {
  guardians: Array<GuardianInput>;
  pupils: Array<PupilInput>;
  recaptchaToken: Scalars['String'];
};

export type CreatePupilsResult = {
  __typename?: 'CreatePupilsResult';
  results: Array<CreatePupilResult>;
};

export enum EnrollmentStatusType {
  Enrolled = 'ENROLLED',
  Graduating = 'GRADUATING',
  GraduatingEarly = 'GRADUATING_EARLY',
  Inactive = 'INACTIVE',
  InvitationAccepted = 'INVITATION_ACCEPTED',
  Invited = 'INVITED',
  OfferedPlace = 'OFFERED_PLACE',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferDeclined = 'OFFER_DECLINED',
  RegisteredInQueue = 'REGISTERED_IN_QUEUE'
}

export type Family = {
  __typename?: 'Family';
  archivedAt?: Maybe<Scalars['Instant']>;
  createdAt: Scalars['Instant'];
  guardians: Array<Guardian>;
  id: Scalars['UUID'];
  pupils: Array<Pupil>;
};

export enum GenderType {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Guardian = {
  __typename?: 'Guardian';
  address: Scalars['String'];
  archivedAt?: Maybe<Scalars['Instant']>;
  createdAt: Scalars['Instant'];
  email: Scalars['String'];
  familyId: Scalars['UUID'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  postAddress: Scalars['String'];
  relationToPupil: RelationToPupilType;
  zipCode: Scalars['String'];
};

export type GuardianInput = {
  address: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  postAddress: Scalars['String'];
  relationToPupil?: InputMaybe<RelationToPupilType>;
  zipCode: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createOrUpdateOrder?: Maybe<Order>;
  createOrUpdateOrderDraft?: Maybe<Order>;
  createPaymentRequest?: Maybe<Payment>;
  createPupilExistingFamily: CreatePupilsResult;
  createPupilNewFamily: CreatePupilsResult;
  createPupils: CreatePupilsResult;
};


export type MutationCreateOrUpdateOrderArgs = {
  pupilIds: Array<Scalars['UUID']>;
};


export type MutationCreateOrUpdateOrderDraftArgs = {
  pupilIds: Array<Scalars['UUID']>;
};


export type MutationCreatePaymentRequestArgs = {
  orderId: Scalars['UUID'];
};


export type MutationCreatePupilExistingFamilyArgs = {
  input: CreatePupilsExistingFamilyInput;
};


export type MutationCreatePupilNewFamilyArgs = {
  input: CreatePupilsNewFamilyInput;
};


export type MutationCreatePupilsArgs = {
  input: CreatePupilsInput;
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['Instant'];
  dueDateAt: Scalars['Instant'];
  familyId: Scalars['UUID'];
  id: Scalars['UUID'];
  items: Array<OrderItem>;
  schoolYearId: Scalars['UUID'];
  status: OrderStatus;
  statusUpdatedAt: Scalars['Instant'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  description: Scalars['String'];
  id: Scalars['UUID'];
  itemId: Scalars['UUID'];
  itemType: OrderItemType;
  orderId: Scalars['UUID'];
  rowNumber: Scalars['Int'];
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export enum OrderItemType {
  Discount = 'DISCOUNT',
  Pupil = 'PUPIL'
}

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Draft = 'DRAFT',
  Paid = 'PAID'
}

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float'];
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  message: Scalars['String'];
  method: PaymentMethod;
  orderId: Scalars['UUID'];
  receiptSentAt?: Maybe<Scalars['Instant']>;
  status: PaymentStatus;
  statusUpdatedAt: Scalars['Instant'];
  token?: Maybe<Scalars['String']>;
};

export enum PaymentMethod {
  Swish = 'SWISH'
}

export enum PaymentStatus {
  Cancelled = 'CANCELLED',
  CreatedRequest = 'CREATED_REQUEST',
  Declined = 'DECLINED',
  Error = 'ERROR',
  Paid = 'PAID'
}

export type Pupil = {
  __typename?: 'Pupil';
  address: Scalars['String'];
  archivedAt?: Maybe<Scalars['Instant']>;
  createdAt: Scalars['Instant'];
  email?: Maybe<Scalars['String']>;
  enrollmentStatus?: Maybe<EnrollmentStatusType>;
  familyId: Scalars['UUID'];
  firstName: Scalars['String'];
  gender: GenderType;
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  postAddress: Scalars['String'];
  socialSecurityNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

export type PupilInput = {
  address: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: GenderType;
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  postAddress: Scalars['String'];
  socialSecurityNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

export type PupilsPaymentStatus = {
  __typename?: 'PupilsPaymentStatus';
  familyId: Scalars['UUID'];
  paidPupils: Array<Pupil>;
  partiallyPaidPupils: Array<Pupil>;
  unpaidPupils: Array<Pupil>;
};

export type Query = {
  __typename?: 'Query';
  getAllPupilsInQueue: Array<Pupil>;
  getFamilyById?: Maybe<Family>;
  getFamilyByPupilSocialSecurityNumber?: Maybe<Family>;
  getPayment?: Maybe<Payment>;
  getPupilBySocialSecurityNumber?: Maybe<Pupil>;
  getPupilsPaymentStatus?: Maybe<PupilsPaymentStatus>;
};


export type QueryGetFamilyByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryGetFamilyByPupilSocialSecurityNumberArgs = {
  socialSecurityNumber: Scalars['String'];
};


export type QueryGetPaymentArgs = {
  id: Scalars['UUID'];
};


export type QueryGetPupilBySocialSecurityNumberArgs = {
  socialSecurityNumber: Scalars['String'];
};


export type QueryGetPupilsPaymentStatusArgs = {
  familyId: Scalars['UUID'];
};

export enum RelationToPupilType {
  Aunt = 'AUNT',
  Father = 'FATHER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Mother = 'MOTHER',
  Other = 'OTHER',
  Uncle = 'UNCLE',
  Undefined = 'UNDEFINED'
}

export type GetFamilyByPupilSocialSecurityNumberQueryVariables = Exact<{
  socialSecurityNumber: Scalars['String'];
}>;


export type GetFamilyByPupilSocialSecurityNumberQuery = { __typename?: 'Query', getFamilyByPupilSocialSecurityNumber?: { __typename?: 'Family', id: any, pupils: Array<{ __typename?: 'Pupil', firstName: string, lastName: string, address: string, zipCode: string, postAddress: string }>, guardians: Array<{ __typename?: 'Guardian', id: any, relationToPupil: RelationToPupilType, firstName: string, lastName: string, mobileNumber: string, email: string }> } | null };

export type CreatePupilaMutationVariables = Exact<{
  createPupilsInput: CreatePupilsInput;
}>;


export type CreatePupilaMutation = { __typename?: 'Mutation', createPupils: { __typename?: 'CreatePupilsResult', results: Array<{ __typename: 'CreatePupilFailed', socialSecurityNumber: string, reason: CreatePupilFailedReason } | { __typename: 'CreatePupilSuccessful', pupil: { __typename?: 'Pupil', familyId: any, socialSecurityNumber: string, firstName: string, lastName: string } }> } };


export const GetFamilyByPupilSocialSecurityNumberDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getFamilyByPupilSocialSecurityNumber"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"socialSecurityNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getFamilyByPupilSocialSecurityNumber"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"socialSecurityNumber"},"value":{"kind":"Variable","name":{"kind":"Name","value":"socialSecurityNumber"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"pupils"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"zipCode"}},{"kind":"Field","name":{"kind":"Name","value":"postAddress"}}]}},{"kind":"Field","name":{"kind":"Name","value":"guardians"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"relationToPupil"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"mobileNumber"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<GetFamilyByPupilSocialSecurityNumberQuery, GetFamilyByPupilSocialSecurityNumberQueryVariables>;
export const CreatePupilaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createPupila"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createPupilsInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePupilsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPupils"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createPupilsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePupilSuccessful"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pupil"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"familyId"}},{"kind":"Field","name":{"kind":"Name","value":"socialSecurityNumber"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePupilFailed"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"socialSecurityNumber"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreatePupilaMutation, CreatePupilaMutationVariables>;