import { Footer } from "./components/footer";

export function TermsAndConditions() {
  return (
    <div className="main-side p-5 mr-2">
      <h1 className="main-content title block">شروط ومقررات المدرسة</h1>
      <ol className="main-content mx-2 has-text-right">
        <li>
          مدرسة الثقلين تابعة لجمعية الوحدة الثقافية ومع تسجيل الطالب في المدرسة
          يوافق ولي الأمر على تسجيل الطالب كعضو في جمعية الوحدة (Al&#8209;Wahda
          Kulturförening).
        </li>
        <li>
          تأكيد القبول يتم عبر دفع الرسوم وذلك قبل 30 يونيو. في حال تأخير دفع
          الرسوم يعطى المقعد الدراسي لطالب آخر في قائمة الإنتظار.
        </li>
        <li>
          الحضور في اول يوم دراسي اجباري وعدم الحضور يؤدي الى الغاء المقعد
          الدراسي واعطائه الى طالب اخر و الرسوم لا تسترجع.
        </li>
        <li>الرسوم غير قابلة للاسترجاع مطلقاً بعد بدأ اول يوم دراسي في المدرسة.</li>
        <li>
          يشترط التزام الطالب بالدوام وفي حال تجاوز الغيابات لأربع أسابيع متتالية يُلغى المقعد الدراسي.
        </li>
        <li>
          بعد ستة غيابات متتالية حتى في حال المُجاز يٌلغى المقعد الدراسي، إلا إذا كان الغياب مؤيّداً من قِبل الإدارة مُسبقا.
        </li>
        <li>
          الغياب الكثير المتقطّع قد يؤدّي إلى إلغاء المقعد الدراسي ما لم يكن السبب مبرر.
        </li>
        <li>
          إن أصبح التدريس لأي سبب من الأسباب غير ميداني يُعد عدم حضور الطالب للدرس عبر الفضاء المجازي غياب.
        </li>
        <li>
          في حال اعطاء الدرس عبر الفضاء المجازي يجب احترام الصف كما لو كان ميدانيا.
        </li>
        <li>لا يُسمح للطالب الخروج مطلقا خارج المدرسة أثناء الدوام.</li>
        <li>
          حمل الطلاب للهواتف النقالة أثناء الدوام ممنوع، ويشمل ذلك أوقات الفرصة أيضاً. في حال جلب الطالب للهاتف النقال يتم خزنه من قِبل الأستاذ في صندوق الأماتات مع بدء أول درس حتى انتهاء الدوام.
        </li>
        <li>
          المدرسة لا تتحمل المسؤولية في حال تلف أو فقدان الهاتف.
        </li>
        <li>المحافظة على بيت المال ونظافة المدرسة من صفوف وأثاث وغيرها.</li>
        <li>الإلتزام بالهدوء أثناء الدرس وبغير ذلك يحول الطالب الى الإدارة.</li>
        <li>مراعاة الإحترام والتقدير لجهود الكادر التدريسي وسعيهم لتعليم أبنائنا.</li>
        <li>حث و مساعدة أولياء الأمور لأبنائهم على حل الواجب البيتي.</li>
        <li>
          للتواصل مع إدارة المدرسة يرجى إرسال بريد الكتروني إلى <a href="mailto:info@althaqalayn.se">info@althaqalayn.se</a>
        </li>
      </ol>
      <Footer />
    </div>
  )
}