import { RelationToPupilType } from "../../../__generated__/graphql";

export enum RelationType {
  UNDEFINED = "Relation",
  FATHER = "Fader",
  MOTHER = "Moder",
  GRANDFATHER = "Farfar/Morfar",
  GRANDMOTHER = "Farmor/Mormor",
  UNCLE = "Farbror/Morbror",
  AUNT = "Faster/Moster",
  OTHER = "Annan",
}

export const convertRelationTypeToGql = (
  r: RelationType
): RelationToPupilType => {
  switch (r) {
    case RelationType.FATHER:
      return RelationToPupilType.Father;
    case RelationType.MOTHER:
      return RelationToPupilType.Mother;
    case RelationType.GRANDFATHER:
      return RelationToPupilType.Grandfather;
    case RelationType.GRANDMOTHER:
      return RelationToPupilType.Grandmother;
    case RelationType.UNCLE:
      return RelationToPupilType.Uncle;
    case RelationType.AUNT:
      return RelationToPupilType.Aunt;
    case RelationType.OTHER:
      return RelationToPupilType.Other;
    case RelationType.UNDEFINED:
    default:
      return RelationToPupilType.Undefined;
  }
};

export const convertGqlToRelationType = (
  r: RelationToPupilType
): RelationType => {
  switch (r) {
    case RelationToPupilType.Father:
      return RelationType.FATHER;
    case RelationToPupilType.Mother:
      return RelationType.MOTHER;
    case RelationToPupilType.Grandfather:
      return RelationType.GRANDFATHER;
    case RelationToPupilType.Grandmother:
      return RelationType.GRANDMOTHER;
    case RelationToPupilType.Uncle:
      return RelationType.UNCLE;
    case RelationToPupilType.Aunt:
      return RelationType.AUNT;
    case RelationToPupilType.Other:
      return RelationType.OTHER;
    case RelationToPupilType.Undefined:
    default:
      return RelationType.UNDEFINED;
  }
};
