export function Banner({ step, noSteps }: { step?: number, noSteps?: number }) {
  let stepWidget;
  if (step && noSteps) {
    stepWidget = <div className="level-left mx-2"><div className="level-item has-text-black"><div className="circle is-size-7" dir="ltr" style={{ fontFamily: "sans-serif" }}>{step}/{noSteps}</div></div></div>;
  }

  return (
    <header className="has-background-primary px-4 py-4">
      <div className="level is-mobile">
        <div className="level-right mx-2 py-3">
          <h1 className="level-item title is-4 has-text-white">مدرسة الثقلين</h1>
        </div>
        {stepWidget}
      </div>
    </header>
  )
}