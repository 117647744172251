import { useState, useEffect } from "react";
import { Banner } from "../banner";
import { Footer } from "../footer";
import { CheckNewFamily } from "./1-check-new-family";
import { GetExistingFamily } from "./2-get-existing-family";
import { SetGuardianInfo } from "./3-set-guardian-info";
import { IFormState, FormState } from "./domain/FormState";
import { IFormData, FormData } from "./domain/FormData";
import { SetPupilInfo } from "./4-set-pupil-info";
import { InputConfirmation } from "./5-input-confirmation";
import { Confirmation } from "./6-confirmation";

export function RegistrationFrom() {
  const numberOfPages: number = 5;
  const [formState, setFormState] = useState<IFormState>(new FormState(1));

  const [formData, setFormData] = useState<IFormData>(new FormData());

  useEffect(() => {
    if (formState.goNext === true) {
      if (formState.currentPage === 1) {
        if (formData.isNewFamily === true) {
          setFormState({ currentPage: 3, goBack: false, goNext: false });
        } else if (formData.isNewFamily === false) {
          setFormState({ currentPage: 2, goBack: false, goNext: false });
        }
      } else if (formState.currentPage > 1 && formState.currentPage <= numberOfPages) {
        setFormState({ currentPage: formState.currentPage + 1, goBack: false, goNext: false });
      }
    } else if (formState.goBack === true) {
      if (formState.currentPage === 3) {
        if (formData.isNewFamily === true) {
          setFormState({ currentPage: 1, goBack: false, goNext: false });
        } else if (formData.isNewFamily === false) {
          setFormState({ currentPage: 2, goBack: false, goNext: false });
        }
      } else if (formState.currentPage > 1) {
        setFormState({ currentPage: formState.currentPage - 1, goBack: false, goNext: false });
      }
    }
  }, [formState.currentPage, formState.goBack, formState.goNext, formData.isNewFamily])



  let showPage = <p></p>;
  switch (formState.currentPage) {
    case 1:
      showPage = <CheckNewFamily formData={formData} setFormData={setFormData} formState={formState} setFormState={setFormState} />
      break;
    case 2:
      showPage = <GetExistingFamily formData={formData} setFormData={setFormData} formState={formState} setFormState={setFormState} />
      break;
    case 3:
      showPage = <SetGuardianInfo formData={formData} setFormData={setFormData} formState={formState} setFormState={setFormState} />
      break;
    case 4:
      showPage = <SetPupilInfo formData={formData} setFormData={setFormData} formState={formState} setFormState={setFormState} />
      break;
    case 5:
      showPage = <InputConfirmation formData={formData} setFormData={setFormData} formState={formState} setFormState={setFormState} />
      break;
  }

    if (formState.currentPage === 6) {
      return (<Confirmation formData={formData} setFormData={setFormData} formState={formState} setFormState={setFormState} />)
    } else {
      return (
        <div className="main-side">
          <Banner step={formState.currentPage} noSteps={numberOfPages} />
          {showPage}
          <Footer />
        </div>
      );
    }
}