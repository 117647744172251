import React from 'react';
import leftQoute from './assets/left_qoute.png';
import "./mystyles.scss";
import './App.scss';
import { Outlet, Link } from 'react-router-dom';
import { Footer } from './components/footer';

function Left() {
  return (
    <div className="left-side">
      <img className="left-qoute" src={leftQoute} alt="قال رسول الله (ص): العلم فريضة على كل مسلم ومسلمة." />
    </div>
  )
}

export function Main() {
  const today = new Date();
  let infoRegistrationTimeline;
  if (today.getMonth() >= 0 && today.getMonth() < 4) {
    const schoolYear = today.getFullYear() + "/" + (today.getFullYear() + 1);
    infoRegistrationTimeline = <span>نستقبل تسجيل الطلاب الجُدد للسنة الدراسية <span style={{ fontFamily: "sans-serif" }}>{schoolYear}</span> <span className='has-text-danger'>حتى يوم <span style={{ fontFamily: "sans-serif" }}>30</span> أبريل</span>. الطالب الذي يُسجّل بعد هذا التاريخ سوف يكون في قائمة الاحتياط.</span>;
  } else if (today.getMonth() >= 4 && today.getMonth() < 8) {
    const schoolYear = today.getFullYear() + "/" + (today.getFullYear() + 1);
    const upcommingSchoolYear = (today.getFullYear() + 1) + "/" + (today.getFullYear() + 2);
    infoRegistrationTimeline = <span>كان يوم <span style={{ fontFamily: "sans-serif" }}>30</span> أبريل آخر يوم لموعد التسجيل للسنة الدراسية <span style={{ fontFamily: "sans-serif" }}>{schoolYear}</span> وبذلك <span className='has-text-danger'>يُعتبر التسحيل الآن تسجيلاً متأخراً</span> ولا يُضمن اتخاذ إجراءات القبول لهذا العام الدراسي. التسجيلات المتأخرة والتي لا يتم قبولها تُضاف تلقائيا في قائمة الانتظار للعام الدراسي <span style={{ fontFamily: "sans-serif" }}>{upcommingSchoolYear}</span>.</span>;
  } else {
    const schoolYear = today.getFullYear() + "/" + (today.getFullYear() + 1);
    const upcommingSchoolYear = (today.getFullYear() + 1) + "/" + (today.getFullYear() + 2);
    infoRegistrationTimeline = <span><span className='has-text-danger'>لقد أُغلق باب القبول للسنة الدراسية <span style={{ fontFamily: "sans-serif" }}>{schoolYear}</span></span> ونستقبل الآن تسجيل الطلاب الجُدُد للسنة الدراسية <span style={{ fontFamily: "sans-serif" }}>{upcommingSchoolYear}</span>.</span>;
  }

  return (
    <div className="main-side">
      <section className="main-content main-center-content px-5">
      <h1 className="title is-2 block has-text-primary pt-6">مدرسة الثقلين</h1>
      <div className="block pt-3">
        {infoRegistrationTimeline}
      </div>
      <div className="block pt-5">
      <Link to='/terms-and-conditions'><button className="button is-medium is-dark is-outlined is-rounded">شروط ومقررات المدرسة</button></Link>
      </div>
      <div className="block">
        <Link to='/registration-form'><button className="button is-medium is-rounded is-danger">سجل طفلك في المدرسة</button></Link>
      </div>
      </section>
      <Footer />
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <div className="is-size-5">
        <div className="columns is-marginless is-atleast-fullheight">
          <Outlet />
          <Left />
        </div>
      </div>
    </div >
  );
}

export default App;
