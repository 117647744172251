import { useState } from "react";
import { FormData, Guardian } from "./domain/FormData";
import { FormState } from "./domain/FormState";
import { RelationInputFieldType } from "./domain/InputFieldTypes";
import { RelationType } from "./domain/RelationType";
import {
  ProcessEmailInput,
  ProcessMobileNumberInput,
  ProcessStringInput,
} from "./processInput";

export function SetGuardianInfo({
  formData,
  setFormData,
  formState,
  setFormState,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}) {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>(undefined);

  function updateGuardianData(
    idx: number,
    valueType: string,
    value: string,
    isRequired: boolean,
    doErrorValidation: boolean
  ) {
    const guardians = formData.guardians.map((g, _i) => {
      if (_i === idx) {
        switch (valueType) {
          case "relation":
            const relationInput = new RelationInputFieldType(
              value as RelationType,
              undefined
            );
            if (doErrorValidation && isRequired && relationInput.value === RelationType.UNDEFINED) {
              relationInput.errorMessage = "هذا الحقل إلزامي"
            }
            return { ...g, relation: relationInput };
          case "firstName":
            return {
              ...g,
              firstName: ProcessStringInput(
                g.firstName,
                value,
                isRequired,
                true,
                true,
                doErrorValidation
              ),
            };
          case "lastName":
            return {
              ...g,
              lastName: ProcessStringInput(
                g.lastName,
                value,
                isRequired,
                true,
                true,
                doErrorValidation
              ),
            };
          case "mobileNumber":
            return {
              ...g,
              mobileNumber: ProcessMobileNumberInput(
                g.mobileNumber,
                value,
                isRequired,
                doErrorValidation
              ),
            };
          case "email":
            return {
              ...g,
              email: ProcessEmailInput(
                g.email,
                value,
                isRequired,
                doErrorValidation
              ),
            };
          default:
            return g;
        }
      } else {
        return g;
      }
    })

    let inputsAreOk = true;
    guardians.forEach((g) =>
      inputsAreOk =
        g.firstName.errorMessage ||
        g.lastName.errorMessage ||
        g.mobileNumber.errorMessage ||
        g.email.errorMessage ||
        g.relation.errorMessage
          ? false
          : inputsAreOk
    )

    if (inputsAreOk) {
      setSubmitErrorMessage(undefined);
    }

    setFormData({
      ...formData,
      guardians: guardians,
    });
  }

  function handleNextButtonPressed() {
    let inputsAreOk = true;
    setFormData({
      ...formData,
      guardians: formData.guardians.map((g) => {
        let validatedGuardian = new Guardian(
          g.id,
          {
            ...g.relation,
            errorMessage:
              g.relation.value === RelationType.UNDEFINED
                ? "هذا الحقل إلزامي"
                : undefined,
          },
          ProcessStringInput(g.firstName, g.firstName.value, true, true, true, true),
          ProcessStringInput(g.lastName, g.lastName.value, true, true, true, true),
          ProcessMobileNumberInput(
            g.mobileNumber,
            g.mobileNumber.value,
            true,
            true
          ),
          ProcessEmailInput(g.email, g.email.value, true, true)
        );
        inputsAreOk =
          validatedGuardian.firstName.errorMessage ||
          validatedGuardian.lastName.errorMessage ||
          validatedGuardian.mobileNumber.errorMessage ||
          validatedGuardian.email.errorMessage ||
          validatedGuardian.relation.errorMessage
            ? false
            : inputsAreOk;
        return validatedGuardian;
      }),
    });

    if (inputsAreOk) {
      setSubmitErrorMessage(undefined);
      setFormState({ ...formState, goNext: true });
    } else {
      setSubmitErrorMessage("هنالك خطأ في حقل من الحقول أعلاه يُرجى مراجعتها.");
    }
  }

  return (
    <>
      <section className="main-content pt-5 px-5">
        <>
          {formData.guardians.map((guardian, _idx) => (
            <div key={_idx}>
              <p className="has-text-right has-text-danger block">
                ولي الأمر {_idx + 1}
                {formData.isNewFamily === true &&  _idx > 0 && (
                  <a
                    href="#"
                    className="is-pulled-left"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        guardians: formData.guardians.filter(
                          (g, _i) => _i !== _idx
                        ),
                      })
                    }
                  >
                    لا يوجد ولي أمر آخر
                  </a>
                )}
              </p>

              <div className="mb-3">
                <div
                  className={`select is-normal is-fullwidth ${
                    guardian.relation.errorMessage ? "is-danger" : ""
                  }`}
                >
                  <select
                    value={guardian.relation.value}
                    onChange={(e) => updateGuardianData(_idx, "relation", e.target.value, true, true)}
                  >
                    {Object.values(RelationType).map((value, index) => (
                      <option
                        key={index}
                        value={value}
                        hidden={value === RelationType.UNDEFINED ? true : false}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                {guardian.relation.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {guardian.relation.errorMessage}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <input
                  className={`input ${
                    guardian.firstName.errorMessage ? "is-danger" : ""
                  }`}
                  type="text"
                  placeholder="Förnamn"
                  disabled={
                    formData.isNewFamily !== true && guardian.id !== null
                  }
                  value={guardian.firstName.value}
                  onChange={(e) =>
                    updateGuardianData(
                      _idx,
                      "firstName",
                      e.target.value,
                      true,
                      false
                    )
                  }
                  onBlur={(e) =>
                    updateGuardianData(
                      _idx,
                      "firstName",
                      e.target.value,
                      true,
                      true
                    )
                  }
                />
                {guardian.firstName.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {guardian.firstName.errorMessage}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <input
                  className={`input ${
                    guardian.lastName.errorMessage ? "is-danger" : ""
                  }`}
                  type="text"
                  placeholder="Efternamn"
                  disabled={
                    formData.isNewFamily !== true && guardian.id !== null
                  }
                  value={guardian.lastName.value}
                  onChange={(e) =>
                    updateGuardianData(
                      _idx,
                      "lastName",
                      e.target.value,
                      true,
                      false
                    )
                  }
                  onBlur={(e) =>
                    updateGuardianData(
                      _idx,
                      "lastName",
                      e.target.value,
                      true,
                      true
                    )
                  }
                />
                {guardian.lastName.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {guardian.lastName.errorMessage}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <input
                  className={`input ${
                    guardian.mobileNumber.errorMessage ? "is-danger" : ""
                  }`}
                  type="text"
                  placeholder="Mobiltelefonnummer"
                  disabled={
                    formData.isNewFamily !== true && guardian.id !== null
                  }
                  value={guardian.mobileNumber.value}
                  minLength={11}
                  maxLength={11}
                  onChange={(e) =>
                    updateGuardianData(
                      _idx,
                      "mobileNumber",
                      e.target.value,
                      true,
                      false
                    )
                  }
                  onBlur={(e) =>
                    updateGuardianData(
                      _idx,
                      "mobileNumber",
                      e.target.value,
                      true,
                      true
                    )
                  }
                />
                {guardian.mobileNumber.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {guardian.mobileNumber.errorMessage}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <input
                  className={`input ${
                    guardian.email.errorMessage ? "is-danger" : ""
                  }`}
                  type="email"
                  placeholder="E-post"
                  disabled={
                    formData.isNewFamily !== true && guardian.id !== null
                  }
                  value={guardian.email.value}
                  onChange={(e) =>
                    updateGuardianData(
                      _idx,
                      "email",
                      e.target.value,
                      true,
                      false
                    )
                  }
                  onBlur={(e) =>
                    updateGuardianData(
                      _idx,
                      "email",
                      e.target.value,
                      true,
                      true
                    )
                  }
                />
                {guardian.email.errorMessage && (
                  <p
                    className="help is-danger block"
                    style={{ direction: "rtl" }}
                  >
                    {guardian.email.errorMessage}
                  </p>
                )}
              </div>
            </div>
          ))}
          {formData.isNewFamily === true && formData.guardians.length < 2 && (
            <button
              className="button is-fullwidth is-lighter is-outlined"
              style={{ justifyContent: "center" }}
              onClick={() =>
                setFormData({
                  ...formData,
                  guardians: [...formData.guardians, new Guardian()],
                })
              }
            >
              <strong>أضف ولي أمر آخر</strong>
            </button>
          )}
          {formData.isNewFamily === false && (
            <p
            className="help block"
            style={{ direction: "rtl" }}
          >
            هل هنالك خطأ في المعلومات وتحتاج إلى تحديث؟ الرجاء التواصل معنا بهذا الأمر عبر البريد الالكتروني التالي: <a href="mailto:info@althaqalayn.se">info@althaqalayn.se</a>.
          </p>
          )}
          <div className=" main-center-content">
            <div className="buttons are-medium pt-4">
              <button
                onClick={() => setFormState({ ...formState, goBack: true })}
                className="button is-rounded"
                style={{ minWidth: "125px" }}
              >
                السابق
              </button>
              <button
                onClick={() => handleNextButtonPressed()}
                className="button is-rounded is-danger"
                style={{ minWidth: "125px" }}
              >
                التالي
              </button>
            </div>
          </div>
          {submitErrorMessage && (
            <p className="help is-danger block" style={{ direction: "rtl", whiteSpace: 'pre-wrap' }}>
              {submitErrorMessage}
            </p>
          )}
        </>
      </section>
    </>
  );
}
