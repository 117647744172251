import { FormData, Guardian, Pupil } from "./domain/FormData";
import { FormState } from "./domain/FormState";

export function CheckNewFamily({ formData, setFormData, formState, setFormState }:
  {
    formData: FormData,
    setFormData: React.Dispatch<React.SetStateAction<FormData>>,
    formState: FormState,
    setFormState: React.Dispatch<React.SetStateAction<FormState>>
  }
) {
  return (
    <>
      <section className="main-content main-center-content px-5">
        <div className="title pt-6">
          هل الطالب لديه أخوة في المدرسة؟
        </div>
        <div className="buttons are-medium pt-4">
          <button onClick={() => { setFormData({ ...formData, isNewFamily: true, guardians: [new Guardian, new Guardian], pupils: [new Pupil] }); setFormState({ ...formState, goNext: true }); }} className="button is-rounded is-danger" style={{ minWidth: '125px' }}>لا</button>
          <button onClick={() => { setFormData({ ...formData, isNewFamily: false, guardians: [new Guardian, new Guardian], pupils: [new Pupil] }); setFormState({ ...formState, goNext: true }); }} className="button is-rounded is-primary" style={{ minWidth: '125px' }}>نعم</button>
        </div>
      </section>
    </>
  )
}