export function ErrorPage() {
  return (
    <div className="main-image-error has-text-center"
      >
        <div className="app-border-padding">
          <h1 className="title block is-1" style={{marginTop: "5rem"}}>
            هنالك خطأ بالرابط
          </h1>
          <p style={{marginTop: "25rem"}}>
            في حال لديكم إستفسار يرجى مراجعة إدارة مدرسة الثقلين عبر البريد
            الالكتروني التالي:
            <a href="mailto:info@althaqalayn.se">info@althaqalayn.se</a>
          </p>
        </div>
      </div>
  )
}