import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Main } from './App';
import { TermsAndConditions } from './terms-and-conditions';
import { ErrorPage } from './error';
import { RegistrationFrom } from './components/form/registration-form';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const graphQlClient = new ApolloClient({
  uri: process.env.REACT_APP_ALTHAQALAYN_GQL_API,
  cache: new InMemoryCache(),
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Main />
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />
      },
      {
        path: "registration-form",
        element: <RegistrationFrom />
      },
    ]
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={graphQlClient}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
