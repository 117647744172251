import moment from "moment";
import { GenderType } from "./domain/GenderType";
import { StringInputFieldType } from "./domain/InputFieldTypes";

export function ProcessStringInput(currentValue: StringInputFieldType, input: string, required: boolean, noArabic: boolean, noNumbers: boolean, doErrorValidation: boolean): StringInputFieldType {
  const formatedInput = input;
  const matchesArabic = formatedInput.match(/[ضصثقفغعهخحجدشسيبلاتنمكطئءؤرىةوزظذإأآ]+/g);
  const matchesNumbers = formatedInput.match(/[1234567890١٢٣٤٥٦٧٨٩٠]+/g);

  if (doErrorValidation && formatedInput.length === 0) {
    return { value: formatedInput, errorMessage: required ? "هذا الحقل إلزامي" : undefined }
  } else if (doErrorValidation && noArabic && matchesArabic) {
    return { value: formatedInput, errorMessage: "يرجى ملء الحقل باللغة السويدية" }
  } else if (doErrorValidation && noNumbers && matchesNumbers) {
    return { value: formatedInput, errorMessage: "الحقل لا يقبل الأرقام" }
  } else if ((required && formatedInput.length === 0) || (noArabic && matchesArabic) || (noNumbers && matchesNumbers)) {
    return { value: formatedInput, errorMessage: currentValue.errorMessage }
  } else {
    return { value: formatedInput, errorMessage: undefined }
  }
}

export function ProcessEmailInput(currentValue: StringInputFieldType, input: string, required: boolean, doErrorValidation: boolean): StringInputFieldType {
  const formatedInput = input.trim();
  const match = formatedInput.match(/[a-zA-Z0-9._-]+[@][a-zA-Z0-9._-]+[.][a-zA-Z0-9_-]{1}[a-zA-Z0-9_-]+/g)
  const isValid = match ? match[0] === formatedInput : null

  if (doErrorValidation && formatedInput.length === 0) {
    return { value: formatedInput, errorMessage: required ? "هذا الحقل إلزامي" : undefined }
  } else if (doErrorValidation && !isValid) {
    return { value: formatedInput, errorMessage: "هنالك خطأ في صيغة البريد الالكتروني" }
  } else if (isValid) {
    return { value: formatedInput, errorMessage: undefined }
  } else {
    return { value: formatedInput, errorMessage: currentValue.errorMessage }
  }
}

export function ProcessMobileNumberInput(currentValue: StringInputFieldType, input: string, required: boolean, doErrorValidation: boolean): StringInputFieldType {
  const formatedInput = formatMobileNumber(input.trim());
  const isValid = formatedInput.match(/07[0-9][\\-][0-9]{7}/g);
  
  if (doErrorValidation && formatedInput.length === 0) {
    return { value: formatedInput, errorMessage: required ? "هذا الحقل إلزامي" : undefined }
  } else if (doErrorValidation && formatedInput.length < 11) {
    return { value: formatedInput, errorMessage: "رقم الهاتف المذكور قصير" }
  } else if (doErrorValidation && !isValid) {
    return { value: formatedInput, errorMessage: "هنالك خطأ في صيغة رقم الهاتف، يُرجى كتابته على الشكل التالي (07x-xxxxxxx)" }
  } else if (isValid) {
    return { value: formatedInput, errorMessage: undefined }
  } else {
    return { value: formatedInput, errorMessage: currentValue.errorMessage }
  }
}

function formatMobileNumber(input: string): string {
  if (input.length > 3 && !input.includes("-")) {
    return [input.slice(0, 3), "-", input.slice(3)].join("");
  }
  return input;
}

export function ProcessZipCodeInput(currentValue: StringInputFieldType, input: string, required: boolean, doErrorValidation: boolean): StringInputFieldType {
  const formatedInput = formatZipCode(input.trim());
  const isValid = formatedInput.match(/[0-9]{3}[ ][0-9]{2}/g);

  if (doErrorValidation && formatedInput.length === 0) {
    return { value: formatedInput, errorMessage: required ? "هذا الحقل إلزامي" : undefined }
  } else if (doErrorValidation && formatedInput.length < 6) {
    return { value: formatedInput, errorMessage: "الرقم البريدي المذكور قصير" }
  } else if (doErrorValidation && !isValid) {
    return { value: formatedInput, errorMessage: "هنالك خطأ في صيغة الرقم البريدي، يتكون الرقم البريدي من خمسة أرقام" }
  } else if (isValid) {
    return { value: formatedInput, errorMessage: undefined }
  } else {
    return { value: formatedInput, errorMessage: currentValue.errorMessage }
  }
}

function formatZipCode(input: string): string {
  if (input.length > 3 && input.length < 6 && !input.includes(" ")) {
    return [input.slice(0, 3), " ", input.slice(3)].join("");
  }
  return input;
}

export function ProcessSocialSecurityNumberInput(currentValue: StringInputFieldType, input: string, required: boolean, doErrorValidation: boolean, gender: GenderType): StringInputFieldType {
  const formatedInput = formatTenDigitSocialSecurityNumber(input.trim());
  let isValid = formatedInput.match(/[0-9]{6}[+-][0-9]{4}/g);

  if (doErrorValidation && formatedInput.length === 0) {
    return { value: formatedInput, errorMessage: required ? "هذا الحقل إلزامي" : undefined }
  } else if (doErrorValidation && formatedInput.length < 11) {
    return { value: formatedInput, errorMessage: "الرقم الشخصي المذكور قصير" };
  } else if (doErrorValidation && (formatedInput.length > 11 || !isValid)) {
    return { value: formatedInput, errorMessage: "هنالك خطأ في صيغة الرقم الشخصي، يُرجى كتابته على الشكل التالي (yymmdd-xxxx)" };
  } else if (doErrorValidation && !moment(formatedInput.slice(0, 6), "YYMMDD", true).isValid()) {
    return { value: formatedInput, errorMessage: "تأريخ الولادة يبدو غير صحيح" };
  } else if (
    doErrorValidation && 
    gender !== GenderType.UNDEFINED &&
    !((parseInt(formatedInput[9]) % 2 === 0 && gender === GenderType.FEMALE) ||
      (parseInt(formatedInput[9]) % 2 === 1 && gender === GenderType.MALE))
  ) {
    return { value: formatedInput, errorMessage: "هنالك خطأ في الرقم أو المعلومة المذكورة حول كون الطالب صبي أو بنت" };
  } else if (doErrorValidation && !verifySocialSecurityNumberCheckDigit(formatedInput)) {
    return { value: formatedInput, errorMessage: "هنالك خطأ في الرقم" };
  } else if (isValid) {
    return { value: formatedInput, errorMessage: undefined }
  } else {
    return { value: formatedInput, errorMessage: currentValue.errorMessage }
  }
}

export function formatTenDigitSocialSecurityNumber(input: string) {
  if (input.length > 6 && input.length < 11 && !input.includes("-")) {
    return [input.slice(0, 6), "-", input.slice(6)].join("");
  }
  return input;
}

// Source: https://sv.wikipedia.org/wiki/Luhn-algoritmen
function verifySocialSecurityNumberCheckDigit(socialSecurityNumber: string) {
  let lastPosition = socialSecurityNumber.length;
  let startPosition = socialSecurityNumber.length - 10 - 1;
  let digits = socialSecurityNumber
    .replace(/\D/i, "") // Remove dash
    .slice(startPosition, lastPosition) // Extract last 10 digits of the social security number
    .split(""); // split all characters in the string into array
  if (digits.length != 10) {
    return false;
  }
  var sum = 0;
  digits.forEach(function (value, i) {
    let digit = Number(value);
    sum += digit;
    if (i % 2 === 0) {
      sum += digit < 5 ? digit : digit - 9;
    }
  });
  return sum % 10 === 0;
}